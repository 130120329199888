import objectAssign from "object-assign";
import initialState from "./initialState";
import { UPDATE_APP_SETTINGS } from "../actions/actionTypes";

export default function appSettingsReducer(
  state = initialState.appSettings,
  action
) {
  switch (action.type) {
    case UPDATE_APP_SETTINGS:
      return objectAssign({}, state, action.appSettings);
    default:
      return state;
  }
}
