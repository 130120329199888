class urlHelper {
  static getParameters(url) {
    const params = { UserToken: "" };
    const name = ["userToken"];
    name.forEach(ext => {
      const param = ext.replace(/[\]]/g, "\\$&");
      const regex = new RegExp("[?&]" + param + "(=([^&#]*)|&|#|$)");
      const results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return "";
      switch (param) {
        case "userToken":
          params.UserToken = decodeURIComponent(results[2].replace(/\+/g, " "));
          break;
        default:
          break;
      }
    });
    return params;
  }
}

export default urlHelper;
