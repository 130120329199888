import React from "react";
import { render } from "react-dom";
import { HashRouter } from "react-router-dom";
import configureStore from "./store/configureStore"; // eslint-disable-line import/default
import routes from "./routes";
import "./styles/index.scss";
import { Provider } from "react-redux";

const store = configureStore();

render(
  <Provider store={store}>
    <HashRouter>{routes}</HashRouter>
  </Provider>,
  document.getElementById("root")
);
