import objectAssign from "object-assign";
import initialState from "./initialState";
import { UPDATE_PRODUCT_INFO } from "../actions/actionTypes";

export default function productInfoReducer(
  state = initialState.productInfo,
  action
) {
  switch (action.type) {
    case UPDATE_PRODUCT_INFO:
      return objectAssign({}, state, action.productInfo);
    default:
      return state;
  }
}
