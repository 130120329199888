import React from "react";

function Header() {
  return (
    <React.Fragment>
      <header>
        <div className="secret-display-header">
          <a href="http://developer.vinsolutions.com">
            <img
              className="default-logo"
              src="./images/Vinlogo.png"
              alt="logo"
            />
          </a>
        </div>
      </header>
    </React.Fragment>
  );
}
export default Header;
