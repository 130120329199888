import objectAssign from "object-assign";
import initialState from "./initialState";
import { UPDATE_CAPTCHA_INFO } from "../actions/actionTypes";

export default function captchaInfoReducer(
  state = initialState.captchaInfo,
  action
) {
  switch (action.type) {
    case UPDATE_CAPTCHA_INFO:
      return objectAssign({}, state, action.captchaInfo);
    default:
      return state;
  }
}
