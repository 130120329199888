export function buildRequest(contactInfo, companyInfo, productInfo) {
  return {
    firstName: contactInfo.firstName,
    lastName: contactInfo.lastName,
    title: contactInfo.title,
    phone: contactInfo.phone,
    emailaddress: contactInfo.email,
    coxAutoUsername: contactInfo.coxAutoUsername,
    companyType: companyInfo.companyType,
    isExistingPartner: companyInfo.existingPartner === "yes" ? "1" : "0",
    company: companyInfo.companyName,
    street: companyInfo.streetAddress,
    city: companyInfo.city,
    state: companyInfo.state,
    postalCode: companyInfo.zip,
    productNames: productInfo.applicableProducts,
    numberOfDealers: productInfo.servicedDealers,
    exposeMethod: productInfo.dataExposed,
    consumers: productInfo.dataConsumers,
    notes:
      "CoxAuto Username: " +
      contactInfo.coxAutoUsername +
      "====" +
      " NOTES:" +
      productInfo.comments
  };
}
