// Loadings
export const LOADING = "LOADING";
export const LOADING_COMPLETE = "LOADING_COMPLETE";

// Loading Indicator
export const LOADING_INDICATOR_START = "LOADING_INDICATOR_START";
export const LOADING_INDICATOR_SET_PERCENT = "LOADING_INDICATOR_SET_PERCENT";

export const UPDATE_CONTACT_INFO = "UPDATE_CONTACT_INFO";
export const UPDATE_COMPANY_INFO = "UPDATE_COMPANY_INFO";
export const UPDATE_PRODUCT_INFO = "UPDATE_PRODUCT_INFO";
export const UPDATE_APP_SETTINGS = "UPDATE_APP_SETTINGS";
export const UPDATE_CAPTCHA_INFO = "UPDATE_CAPTCHA_INFO";
export const UPDATE_USER_INFO = "UPDATE_USER_INFO";
