import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "@cx/ui/Grid";
import Col from "@cx/ui/Col";
import Row from "@cx/ui/Row";
import { bindActionCreators } from "redux";
import urlHelper from "../../util/urlHelper";
import Alert from "react-bootstrap/lib/Alert";
import InputGroup from "react-bootstrap/lib/InputGroup";
import TextInput from "@cx/ui/TextInput";
import Clipboard from "react-clipboard.js";
import LoadingSpinner from "@cx/ui/LoadingSpinner";
import * as commonActions from "../../actions/commonActions";
import Header from "../common/Header";
import PropTypes from "prop-types";

class SecretDisplayPage extends Component {
  static propTypes = {
    appSettings: PropTypes.object,
    commonActions: PropTypes.object,
    userInfo: PropTypes.object
  };

  state = {
    copied: false
  };

  componentDidMount() {
    const params = urlHelper.getParameters(window.location.href);
    this.props.commonActions.getUserKeyByUserToken(params.UserToken);
  }

  render() {
    const { userInfo, appSettings } = this.props;

    if (appSettings.loadingSpinner) {
      return (
        <div>
          <Header />
          <LoadingSpinner htmlId="LoadingSpinnerExample" />
        </div>
      );
    }
    if (appSettings.secretAlreadyTaken) {
      return (
        <div>
          <Header />
          <Grid fluid>
            <Row className={"alert-dialog"}>
              <Col sm={6} md={6} lg={6} lgOffset={3}>
                <Alert bsStyle="danger">
                  <p>
                    <strong>
                      Your secret key has already been delivered !!
                    </strong>
                  </p>
                </Alert>
              </Col>
            </Row>
          </Grid>
        </div>
      );
    }
    return (
      <div>
        <Header />
        <Row className="alert-info-dialog center">
          <Col sm={12} md={12} lg={12}>
            <Alert bsStyle="warning">
              <p>
                <strong>
                  Copy and save the included secret, once you leave this page
                  there is no getting back to it.
                </strong>
              </p>
            </Alert>
          </Col>
        </Row>
        <Row className="alert-info-dialog center">
          <Col sm={12} md={12} lg={12}>
            <p>
              <strong>Here is your secret key :</strong>
            </p>
            {this.state.copied ? (
              <span style={{ color: "red" }}>Copied</span>
            ) : null}
            <TextInput
              addOnPosition="append"
              htmlId="textInputAppendChild"
              name="textInputAppend"
              label=""
              onChange={() => ({})}
              maxLength={50}
              disabled
              placeholder="Your secret key"
              value={userInfo.secret}
            >
              <InputGroup.Button>
                <Clipboard
                  data-clipboard-text={userInfo.secret}
                  button-className="btn btn-default"
                  onSuccess={() => this.setState({ copied: true })}
                >
                  Copy
                </Clipboard>
              </InputGroup.Button>
            </TextInput>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    appSettings: state.appSettings
  };
}

function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SecretDisplayPage);
