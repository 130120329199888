import * as types from "./actionTypes";
import objectAssign from "object-assign";

export function registrationSuccess() {
  return function(dispatch, getState) {
    const { appSettings } = getState();
    dispatch({
      type: types.UPDATE_APP_SETTINGS,
      appSettings: objectAssign({}, appSettings, {
        registrationSuccess: true,
        loadingSpinner: false
      })
    });
  };
}

export function registrationFailure() {
  return function(dispatch, getState) {
    const { appSettings } = getState();
    dispatch({
      type: types.UPDATE_APP_SETTINGS,
      appSettings: objectAssign({}, appSettings, {
        registrationSuccess: false,
        loadingSpinner: false,
        registrationFailed: true
      })
    });
  };
}

export function setLoadingSpinner() {
  return function(dispatch, getState) {
    const { appSettings } = getState();
    dispatch({
      type: types.UPDATE_APP_SETTINGS,
      appSettings: objectAssign({}, appSettings, { loadingSpinner: true })
    });
  };
}

export function setContractedDeveloper(value) {
  return function(dispatch, getState) {
    const { appSettings } = getState();
    dispatch({
      type: types.UPDATE_APP_SETTINGS,
      appSettings: objectAssign({}, appSettings, {
        isContractedDeveloper: value
      })
    });
  };
}

export function secretExpired() {
  return function(dispatch, getState) {
    const { appSettings } = getState();
    dispatch({
      type: types.UPDATE_APP_SETTINGS,
      appSettings: objectAssign({}, appSettings, { secretAlreadyTaken: true })
    });
  };
}

export function toggleLoadingSpinner() {
  return function(dispatch, getState) {
    const { appSettings } = getState();
    dispatch({
      type: types.UPDATE_APP_SETTINGS,
      appSettings: objectAssign({}, appSettings, {
        loadingSpinner: !appSettings.loadingSpinner
      })
    });
  };
}
