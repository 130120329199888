import React from "react";
import { Route, Switch } from "react-router-dom";
import HomePage from "./components/pages/HomePage";
import NotFoundPage from "./components/pages/NotFoundPage";
import SecretDisplayPage from "./components/pages/SecretDisplayPage";

export default (
  <Switch>
    <Route exact path="/" component={HomePage} />
    <Route exact path="/api-secret" component={SecretDisplayPage} />
    <Route component={NotFoundPage} />
  </Switch>
);
