import objectAssign from "object-assign";
import initialState from "./initialState";
import { UPDATE_USER_INFO } from "../actions/actionTypes";

export default function userInfoReducer(state = initialState.userInfo, action) {
  switch (action.type) {
    case UPDATE_USER_INFO:
      return objectAssign({}, state, action.userInfo);
    default:
      return state;
  }
}
