// This is the app's initial state. Note that reducers each handle a slice of this state.
// Composing all initial state here gives us a clear spot of reference that displays the shape
// of our entire store.
export default {
  ajaxCallsInProgress: 0,
  loadingIndicatorStatus: {
    percent: -1,
    intervalTime: 200
  },
  contactInfo: {
    firstName: "",
    lastName: "",
    title: "",
    phone: "",
    email: "",
    coxAutoUsername: ""
  },
  companyInfo: {
    companyType: "",
    existingPartner: "",
    companyName: "",
    streetAddress: "",
    city: "",
    state: "",
    zip: ""
  },
  productInfo: {
    applicableProducts: "",
    servicedDealers: "",
    dataExposed: "",
    dataConsumers: "",
    comments: ""
  },
  captchaInfo: {
    responseToken: ""
  },
  userInfo: {
    token: "",
    secret: ""
  },
  appSettings: {
    isContractedDeveloper: false,
    registrationSuccess: false,
    registrationFailed: false,
    loadingSpinner: false,
    secretAlreadyTaken: false,
    reCaptchaSiteKey: "6LcTJFwUAAAAABAicFnu6XDokmpNfmOwOSqsP_t5"
  }
};
