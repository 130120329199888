export function getApiConfig(captchaToken) {
  return {
    baseUrls: {
      local: {
        app: "http://localhost:3000",
        api: "http://localhost:53960"
      },
      development: {
        app: "https://dev-gatewayregistration-wfe.vinsolutions.com",
        api: "https://dev-gatewayregistration-api.vinsolutions.com"
      },
      qa: {
        app: "https://qa-gatewayregistration-wfe.vinsolutions.com",
        api: "https://qa-gatewayregistration-api.vinsolutions.com"
      },
      qts: {
        app: "https://qts-gatewayregistration-wfe.vinsolutions.com",
        api: "https://qts-gatewayregistration-api.vinsolutions.com"
      },
      production: {
        app: "https://gatewayregistration-wfe.vinsolutions.com",
        api: "https://gatewayregistration-api.vinsolutions.com"
      }
    },
    headers: { reCaptchaToken: captchaToken }
  };
}

export function getMicolashApiConfig() {
  return {
    baseUrls: {
      local: {
        app: "http://localhost:3000",
        api: "https://hwqxdmmflk.execute-api.us-east-1.amazonaws.com"
      },
      development: {
        app: "https://dev-gatewayregistration-wfe.vinsolutions.com",
        api: "https://t4ma4ud7lh.execute-api.us-east-1.amazonaws.com"
      },
      qa: {
        app: "https://qa-gatewayregistration-wfe.vinsolutions.com",
        api: "https://esiw1i1hu1.execute-api.us-east-1.amazonaws.com"
      },
      qts: {
        app: "https://qts-gatewayregistration-wfe.vinsolutions.com",
        api: "https://0070mwyeab.execute-api.us-east-1.amazonaws.com"
      },
      production: {
        app: "https://gatewayregistration-wfe.vinsolutions.com",
        api: "https://0ualrr4s98.execute-api.us-east-1.amazonaws.com"
      }
    },
    withCredentials: false
  };
}
