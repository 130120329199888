import * as types from "./actionTypes";
import objectAssign from "object-assign";
import { buildRequest } from "../util/requestHelper";
import { registerNewUser } from "../api/gatewayRegistrationApi";
import * as appSettingsActions from "./appSettingsActions";
import { getSecretKey } from "../api/micolashApi";

export function updateContactInfo(contact) {
  return function(dispatch, getState) {
    const { contactInfo } = getState();
    dispatch({
      type: types.UPDATE_CONTACT_INFO,
      contactInfo: objectAssign({}, contactInfo, contact)
    });
  };
}

export function updateCompanyInfo(company) {
  return function(dispatch, getState) {
    const { companyInfo } = getState();
    dispatch({
      type: types.UPDATE_COMPANY_INFO,
      companyInfo: objectAssign({}, companyInfo, company)
    });
  };
}

export function updateProductInfo(product) {
  return function(dispatch, getState) {
    const { productInfo } = getState();
    dispatch({
      type: types.UPDATE_PRODUCT_INFO,
      productInfo: objectAssign({}, productInfo, product)
    });
  };
}

export function updateCaptchaInfo(captcha) {
  return function(dispatch, getState) {
    const { captchaInfo } = getState();
    dispatch({
      type: types.UPDATE_CAPTCHA_INFO,
      captchaInfo: objectAssign({}, captchaInfo, {
        responseToken: captcha.responseToken
      })
    });
  };
}

export function submitRegistrationForm() {
  return function(dispatch, getState) {
    const { contactInfo, companyInfo, productInfo, captchaInfo } = getState();
    const request = buildRequest(contactInfo, companyInfo, productInfo);
    dispatch(appSettingsActions.setLoadingSpinner());
    registerNewUser(request, captchaInfo.responseToken).then(
      function(res) {
        if (res.data.success === true) {
          dispatch(appSettingsActions.registrationSuccess());
        } else {
          dispatch(appSettingsActions.registrationFailure());
        }
      },
      function() {
        dispatch(appSettingsActions.registrationFailure());
      }
    );
  };
}

export function getUserKeyByUserToken(userToken) {
  return function(dispatch, getState) {
    const { userInfo } = getState();
    dispatch(appSettingsActions.setLoadingSpinner());
    getSecretKey(userToken).then(
      function(res) {
        if (res.data.success) {
          dispatch({
            type: types.UPDATE_USER_INFO,
            userInfo: objectAssign({}, userInfo, {
              token: userToken,
              secret: res.data.secret
            })
          });
          dispatch(appSettingsActions.toggleLoadingSpinner());
        } else {
          dispatch(appSettingsActions.toggleLoadingSpinner());
          dispatch(appSettingsActions.secretExpired());
        }
      },
      function() {
        dispatch(appSettingsActions.toggleLoadingSpinner());
        dispatch(appSettingsActions.secretExpired());
      }
    );
  };
}
