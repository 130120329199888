import { combineReducers } from "redux";
import contactInfo from "./contactInfoReducer";
import companyInfo from "./companyInfoReducer";
import productInfo from "./productInfoReducer";
import appSettings from "./appSettingsReducer";
import captchaInfo from "./captchaInfoReducer";
import userInfo from "./userInfoReducer";

const rootReducer = combineReducers({
  contactInfo,
  companyInfo,
  productInfo,
  captchaInfo,
  userInfo,
  appSettings
});

export default rootReducer;
